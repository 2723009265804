import axiosInstance from '../axios/index_backend'

export const httpService = {
  preQualification: payload => {
    return axiosInstance.post(`/tower/pre`, payload, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  eusSubmission: payload => {
    return axiosInstance.post(`/tower/eus`, payload, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  checkUC: zip => {
    return axiosInstance.get(`/uc/check/${zip}`)
  }
}
