import React, { useState } from 'react'

function ServiceCard ({
  index,
  serv,
  toggleSelectedServices,
  isIdInSelectedServicesIds
}) {
  const [hidemore, setHidemore] = useState(
    serv?.description?.length > 73 ? true : false || false
  )

  function handleSeemore (e) {
    e.stopPropagation()

    setHidemore(false)
  }
  function handleSeeless (e) {
    e.stopPropagation()

    setHidemore(true)
  }

  return (
    <div className='col-lg-4' key={index}>
      <div
        onClick={() => {
          toggleSelectedServices(serv)
        }}
        className={`card shadow-block ${
          isIdInSelectedServicesIds(serv.id) ? 'selected-package-premier' : ''
        }`}
      >
        <div className='plan addons'>
          <img
            src={
              serv.files[0]
                ? serv.files[0]
                : require('../images/icon-server.png')
            }
            className=''
            alt='...'
          />
          {/* <h3>  </h3> */}
        </div>
        <div className='card-body'>
          <h5 className='card-title'> {serv.srv_name} <span style={{color:'#4db193', fontWeight:'bolder', fontSize:'17px'}}>{serv.download_speed_in_kbps!=null ? '(' + Math.floor(parseInt(serv.download_speed_in_kbps)/1000) + ' Mbps' + ')' : ''}</span></h5>
          <p className={`card-text ${hidemore ? 'hide-more' : ''}`}>{serv.description}</p>
          <div className='d-flex '>
            <button className='btn btn-primary '>Select</button>
            {((serv?.description && serv?.description?.length > 73) && 
              <>
                <button
                  type='button'
                  class='btn ms-2 btn-outline-secondary'
                  onClick={handleSeemore}
                  style={{ display: !hidemore ? 'none' : 'block' }}
                >
                  See more
                </button>
                <button
                  type='button'
                  class='btn ms-2 btn-outline-secondary'
                  onClick={handleSeeless}
                  style={{ display: hidemore ? 'none' : 'block' }}
                >
                  See less
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceCard
