import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {
  GoogleMap,
  LoadScript,
  MarkerF,
  InfoWindowF
} from '@react-google-maps/api'
import {
  setNCstep2,
  setncstepper1,
  setncstepper2,
  setLatitudeNC,
  setLongitudeNC
} from '../../../redux/Actions'
function Step1 ({ address, styleE }) {
  const location = useLocation()
  const dispatch = useDispatch()
  let navigate = useNavigate()

  const [currentPosition, setCurrentPosition] = useState({})
  if(location.state == null){
    window.location.replace('/');
  }
  const inputUserPayload = useSelector(state => state.inputUserPayload)

  const lat = useSelector(state => state.latitudeNC)
  const lng = useSelector(state => state.longitudeNC)
  

  const onMarkerDragEnd = e => {
    const lat = e.latLng.lat()
    const lng = e.latLng.lng()

    dispatch(setLatitudeNC(lat))
    dispatch(setLongitudeNC(lng))

    setCurrentPosition({ lat, lng })
  }
  const mapStyles = {
    height: '50vh',
    width: '100%'
  }
  const defaultCenter = {
    lat: location.state.geometry.location.lat,
    lng: location.state?.geometry?.location.lng
  }
  function handleNext () {
    dispatch(setNCstep2())
    dispatch(setncstepper2())
  }
  function handlePrevious () {
    navigate('/', { state: inputUserPayload })
  }
  return (
    <>
      <div className='panel panel-primary setup-content' style={{ ...styleE }}>
        <div className='inner-upper-block'>
          <div className='panel-heading'>
            <h3 className='panel-title '>We found this address </h3>
          </div>
          <div className='panel-body'>
            <address>
              <strong>{address} </strong>
              <p>
                <em>
                  {' '}
                  To provide you with the best service possible, please verify
                  that the red pin on the map below is at the exact location of
                  your services address{' '}
                </em>
              </p>
            </address>
            <LoadScript googleMapsApiKey='AIzaSyDrsiqmgVCkI4wblWh4bn1H-UgaJ3dW3nM&'>
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={15}
                center={{
                  lat : lat ? lat : location.state.geometry.location.lat,
                  lng : lng ? lng : location.state.geometry.location.lng
                }}
              >
                <MarkerF
                  position={location.state.geometry.location}
                  draggable={true}
                  onDrag={e =>
                    console.log('dragging', location.state.geometry.location)
                  }
                  onDragEnd={onMarkerDragEnd}
                />
              </GoogleMap>
            </LoadScript>
          </div>
          <hr />
        </div>
        <div class='_3uApM '>
        <a class='_2pGos _3CDiP  ' onClick={handlePrevious}>
          Previous
        </a>
          <a onClick={handleNext} class='_2pGos _hsN1w '>
            Next
          </a>
        </div>
      </div>
    </>
  )
}

export default Step1
