import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { httpService } from '../../services/PreQualification'
import { ToastContainer, toast } from 'react-toastify'

import {
  GOOGLE_MAP_API_BASE_URL,
  GOOGLE_MAP_API_KEY_URL,
  GOOGLE_MAP_API_KEY
} from '../../constants/apiConstants'
import {
  GoogleMap,
  LoadScript,
  MarkerF,
  InfoWindowF
} from '@react-google-maps/api'
import {
  setAddressR,
  setCstep2,
  setcstepper2,
  setLatitude,
  setLongitude,
  setNewAddr,
  setUCFlag
} from '../../redux/Actions'
var qs = require('qs')
function Step1({ address, setAddress, styleE }) {
  const [selected, setSelected] = useState({})
  const [currentPosition, setCurrentPosition] = useState({})
  const [mapZipCode, setMapZipCode] = useState('')
  const [loading, setLoading] = useState(false)
  // setMapZipCode('')
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

    if(location.state == null){
      window.location.replace('/');
    }
  const lat = useSelector(state => state.latitude)
  const lng = useSelector(state => state.longitude)
  const addr = useSelector(state => state.address)
  let zipcode = useSelector(state => state.zip)
  // var mapZipCode;
  const inputUserPayload = useSelector(state => state.inputUserPayload)
  const content = useSelector(state => state.content)

  async function checkAdressAgain(){
    if (lat && lng) {
      var res = await axios.post(
        `${GOOGLE_MAP_API_BASE_URL}/json?latlng=${lat},${lng}&key=${GOOGLE_MAP_API_KEY_URL}`
      )

      dispatch(setAddressR(res?.data?.results[0]?.formatted_address))
      setAddress(res?.data?.results[0]?.formatted_address)
    }
  }

  useEffect(()=>{
    checkAdressAgain()
  },[])


 

  


 
  const mapStyles = {
    height: '50vh',
    width: '100%'
  }

  const defaultCenter = {
    lat: location.state?.geometry?.location?.lat,
    lng: location.state?.geometry?.location?.lng
  }
  const onSelect = item => {
    setSelected(item)
  }

  const onMarkerDragEnd = async e => {
    const lat = e.latLng.lat()
    const lng = e.latLng.lng()

    dispatch(setLatitude(lat))
    dispatch(setLongitude(lng))

    setCurrentPosition({ lat, lng })

    var res = await axios.post(
      `${GOOGLE_MAP_API_BASE_URL}/json?latlng=${lat},${lng}&key=${GOOGLE_MAP_API_KEY_URL}`
    )
    console.log(res)
    for (let i = 0; i < res?.data?.results[0].address_components.length; i++) {
      const addressType = res?.data?.results[0].address_components[i].types[0];
      if (addressType === "postal_code") {
        setMapZipCode(res?.data?.results[0].address_components[i].long_name);
        
        break;
      }
    }
    dispatch(setAddressR(res?.data?.results[0]?.formatted_address))
    setAddress(res?.data?.results[0]?.formatted_address)
  }
  function handleNext() {
    
    var data = qs.stringify({
      multicoverageid: '52477',
      Account: '17152',
      Firstname: 'Hunain',
      lastname: 'Arif',
      Address: address ? address : location.state?.formatted_address,
      city: '',
      Country: 'United States',
      State: 'Arkansas',
      zipcode:
      mapZipCode!='' ? mapZipCode :
        zipcode == ''
          ? location.state?.formatted_address.split(',')[1].split(' ')[2]
          : zipcode,
      phonenumber: '+1',
      emailaddress: 'ajay.kumar@icon-solutions.us',
      howdidyouhear: 'facebook',
      preferredmethod: 'Contact field',
      besttimetocontact: 'NULL',
      comments: 'hello',
      clientip: '39.57.229.116',
      Latitude: lat ? lat : location.state.geometry.location.lat,
      Longitude: lng ? lng : location.state.geometry.location.lng,
      key: GOOGLE_MAP_API_KEY,
      RxMargin: ''
    })

    

    setLoading(true)
    
    httpService
      .preQualification(data)
      .then(resp => {
        
        if (resp.data.includes('yes')) {
          navigate('/covered-location', { state: location.state })
          dispatch(setCstep2())
          dispatch(setcstepper2())
          setLoading(false)
        } else if (resp.data.includes('No')) {
          
          httpService
            .checkUC(mapZipCode ? mapZipCode : zipcode == ''
              ? location.state?.formatted_address.split(',')[1].split(' ')[2]
              : zipcode)
            .then(resp => {
              if (resp.data.data.exists) {
                dispatch(setUCFlag(true))
              }
              else {
                
                dispatch(setUCFlag(false))
              }
            })
            .catch(err => {
              toast.error('Something went wrong', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              })
              console.log('err===>', err)
            })

          navigate('/not-covered-location', { state: location.state })
          setLoading(false)
        } else {
          navigate('/not-covered-location', { state: location.state })
          setLoading(false)
        }
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
        toast.error(err.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
      })
  }
  function handlePrevious() {
    navigate('/', { state: inputUserPayload })
  }
  return (
    <div style={{ ...styleE }}>
      <div className={`wrapper ${!loading ? 'd-none' : ''}`}>
        <div class='loader'>
          <div class='loader__part loader__part--1'></div>
          <div class='loader__part loader__part--2'></div>
          <div class='loader__part loader__part--3'></div>
        </div>
      </div>
      <div className='inner-upper-block'>
        <div className='panel-heading'>
          <h3 className='panel-title '>
            {
              content.filter(elem => elem.key == 'ADDRESS_MAP_MAINTITLE')[0]
                ?.value
            }{' '}
          </h3>
        </div>
        <div className='panel-body'>
          <address>
            <strong style={{ color: 'red' }}>{address} </strong>
            <p>
              <em>
                {' '}
                {
                  content.filter(elem => elem.key == 'ADDRESS_MAP_SUBTITLE')[0]
                    ?.value
                }{' '}
              </em>
            </p>
          </address>
          <LoadScript googleMapsApiKey='AIzaSyDrsiqmgVCkI4wblWh4bn1H-UgaJ3dW3nM&'>
            <GoogleMap
              mapContainerStyle={mapStyles}
              zoom={15}
              center={{
                lat: lat ? lat : location.state.geometry.location.lat,
                lng: lng ? lng : location.state.geometry.location.lng
              }}
              options={{
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false
              }}
            >
              <MarkerF
                position={{
                  lat: lat ? lat : location.state.geometry.location.lat,
                  lng: lng ? lng : location.state.geometry.location.lng
                }}
                onDragEnd={onMarkerDragEnd}
                draggable={true}
                onDrag={e =>
                  console.log('dragging', location.state.geometry.location)
                }
              />
            </GoogleMap>
          </LoadScript>
          <ToastContainer toastStyle={{ position: 'fixed' }} />
        </div>
        <hr />
      </div>
      <div class='_3uApM '>
        <a class='_2pGos _3CDiP  ' onClick={handlePrevious}>
          Previous
        </a>
        <a onClick={handleNext} class='_2pGos _hsN1w '>
          Next
        </a>
      </div>
    </div>
  )
}

export default Step1
