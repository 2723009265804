import React, {useState} from 'react'

function PackageCard({index, cval, toggleSelected ,singleSelectedPkgId}) {

  const [hidemore, setHidemore] = useState(cval?.description?.length > 73 ? true : false || false)  

  function handleSeemore(e){
    e.stopPropagation();
    
    setHidemore(false)

  }
  function handleSeeless(e){
    e.stopPropagation();
    
    setHidemore(true)
  }

  return (
    <div className='col-lg-4' key={index}>
                      <div
                        onClick={() => {
                          toggleSelected(cval)
                        }}
                        className={`card shadow-block ${
                          singleSelectedPkgId == cval.id
                            ? 'selected-package-premier'
                            : ''
                        }`}
                      >
                        <div className='plan'>
                          <h3> {cval.pkg_name} </h3>
                          <img
                            src={
                              cval.files[0]
                                ? cval.files[0]
                                : require('../images/icon-basic.png')
                            }
                            className=''
                            alt='...'
                          />
                          {/* <h3> 100 Mbps </h3> */}
                        </div>
                        <div className='card-body'>
                          <h5 className='card-title'>{cval.pkg_name}</h5>
                          <p className={`card-text ${hidemore ? 'hide-more' : ''}`}>{cval.description}</p>
                          <div className='d-flex '> 
                          <button className='btn btn-primary '>
                            Select
                          </button>
                          
                          {
                          (cval?.description && cval?.description?.length > 73) &&  
                            <>
                          <button type="button" class="btn ms-2 btn-outline-secondary" onClick={handleSeemore} style={{display: !hidemore ? 'none' : 'block' }}>See more</button>
                          <button type="button" class="btn ms-2 btn-outline-secondary" onClick={handleSeeless} style={{display: hidemore ? 'none' : 'block' }}>See less</button>
                          </>}
                          </div>
                        </div>
                      </div>
                    </div>
  )
}

export default PackageCard