import React, { useState, useEffect } from 'react'
import StepProgressBar from 'react-step-progress'
import { useLocation } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import notcoveredCSS from "./notcoveredCSS.css";
import 'react-step-progress/dist/index.css'
import Step2 from './Step2'
import Step3 from './Step3'
import tick from '../../images/done.svg'

import axiosInstance from '../../axios/index_backend'
import Step4 from './Step4'
import Step5 from './Step5'

import { useSelector, useDispatch } from 'react-redux'
import Step1 from './Step1'
const CoveredLocation = props => {
  const location = useLocation()
 
  const [address, setAddress] = useState('' || location.state?.formatted_address)
  const addr = useSelector(state => state.address)
  
  const [selectedPkgs, setSelectedPkgs] = useState([])
  const [selectedServicesIds, setSelectedServicesIds] = useState([])
  const [totalInstall, setTotalInstall] = useState(0)
  const [totalMonthly, setTotalMonthly] = useState(0)
  const [services, setServices] = useState([])

  const firstname = useSelector(state => state.firstname)
  const lastname = useSelector(state => state.lastname)
  const email = useSelector(state => state.email)
  const phone = useSelector(state => state.phone)

  const cstep1 = useSelector(state => state.cstep1)
  const cstep2 = useSelector(state => state.cstep2)
  const cstep3 = useSelector(state => state.cstep3)
  const cstep4 = useSelector(state => state.cstep4)
  const cstep5 = useSelector(state => state.cstep5)

  const cstepper1 = useSelector(state => state.cstepper1)
  const cstepper2 = useSelector(state => state.cstepper2)
  const cstepper3 = useSelector(state => state.cstepper3)
  const cstepper4 = useSelector(state => state.cstepper4)
  const cstepper5 = useSelector(state => state.cstepper5)

  const content = useSelector(state => state.content)
  // const onMarkerDragEnd = (coord, index) => {
  // const { latLng } = coord;
  // const lat = latLng.lat();
  // const lng = latLng.lng();

  // this.setState(prevState => {
  //   const markers = [...this.state.markers];
  //   markers[index] = { ...markers[index], position: { lat, lng } };
  //   return { markers };
  // });
  // };

  function step3Validator () {
    // return a boolean
  }

  // function getTableData (obj) {
  //   setSelectedPkgs(obj.selectedPkgs)
  //   setSelectedServicesIds(obj.selectedServicesIds)
  //   setServices(obj.services)
  //   setTotalInstall(obj.totalInstall)
  //   setTotalMonthly(obj.totalMonthly)
  // }

  return (
    <>
      <header>
        <div className='hero-main hero-progress'>
          <div className='container'>
            <div className='logo-container'>
              <img src={require('../../images/logo.png')} alt='' />
            </div>
          </div>
        </div>
      </header>

      <div className='container container-progress-form'>
        <ul class='_1Lo2h '>
          <li className={`_2Jtxm ${cstepper1}`}>
            <span class='_2kL0S'><span className='stepper-count'>1</span> <img src={tick} width="24" alt="" /></span>
            
            <div class='_1hzhf '>{content.filter(elem=>elem.key=='COVERED_STEPS_ONE')[0]?.value}</div>
          </li>
          <li className={`_2Jtxm ${cstepper2}`}>
            <span class='_2kL0S'><span className='stepper-count'>2</span><img src={tick} alt="" width="24"/></span>
            <div class='_1hzhf '>{content.filter(elem=>elem.key=='COVERED_STEPS_TWO')[0]?.value}</div>
          </li>
          <li className={`_2Jtxm ${cstepper3}`}>
            <span class='_2kL0S'><span className='stepper-count'>3</span><img src={tick} alt="" width="24"/></span>
            <div class='_1hzhf '>{content.filter(elem=>elem.key=='COVERED_STEPS_THREE')[0]?.value}</div>
          </li>
          <li className={`_2Jtxm ${cstepper4}`}>
            <span class='_2kL0S'><span className='stepper-count'>4</span><img src={tick} alt="" width="24"/></span>
            <div class='_1hzhf '>{content.filter(elem=>elem.key=='COVERED_STEPS_FOUR')[0]?.value}</div>
          </li>
          {/* <li className={`_2Jtxm ${cstepper5}`}>
            <span class='_2kL0S'><span className='stepper-count'>5</span><img src={tick} alt="" /></span>
            <div class='_1hzhf '>{content.filter(elem=>elem.key=='COVERED_STEPS_FOUR')[0]?.value}</div>
          </li> */}
        </ul>
        {<Step1 address={address} setAddress={setAddress} styleE={{ display: cstep1 ? 'inherit' : 'none' }}/>}
        {
          <Step2
            styleE={{ display: cstep2 ? 'inherit' : 'none' }}
            address={address}
          />
        }
        {
          <Step3
            styleE={{ display: cstep3 ? 'inherit' : 'none' }}
            address={address}
          />
        }
        {
          cstep4 &&
          <Step4
            selectedPkgs={selectedPkgs}
            selectedServicesIds={selectedServicesIds}
            services={services}
            totalInstall={totalInstall}
            totalMonthly={totalMonthly}
            address={address}
            styleE={{ display: cstep4 ? 'inherit' : 'none' }}
          />
        }

        {/* {cstep5 && <Step5 styleE={{ display: cstep5 ? 'inherit' : 'none' }}/>} */}

        
      </div>


    </>
  )
}

export default CoveredLocation
