import React, { useEffect, useState } from 'react'
import AmountTable from '../AmountTable'
import { useDispatch, useSelector } from 'react-redux'
import axiosInstance from '../../axios/index_backend'
import {
  setCstep3,
  setCstep5,
  setAgree,
  setcstepper5,
  setcstepper3,
  setLatitude,
  setLongitude,
  resetState
} from '../../redux/Actions'
import { httpService } from '../../services/PreQualification'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
var qs = require('qs')

function Step4 ({ address, styleE }) {
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const location = useLocation()
  const firstname = useSelector(state => state.firstname)
  const lastname = useSelector(state => state.lastname)
  const email = useSelector(state => state.email)
  const phone = useSelector(state => state.phone)
  const zipcode = useSelector(state => state.zip)
  const tabledata = useSelector(state => state.obj)
  const agree = useSelector(state => state.agree)

  const lat = useSelector(state => state.latitude)
  const lng = useSelector(state => state.longitude)

  const addr = useSelector(state => state.address)

  const [pleaseAgreeMsg, setPleaseAgreeMsg] = useState(false)

  const [loading, setLoading] = useState(false)

  const selectedPackagesIds = useSelector(state => state.selectedPackagesIds)
  const selectedServicesIds = useSelector(state => state.selectedServicesIds)

  const cstepper5 = useSelector(state => state.cstepper5)

  const inputUserPayload = useSelector(state => state.inputUserPayload)
  const content = useSelector(state => state.content)

  

  const data = qs.stringify({
    multicoverageid: '52477',
    Account: '17152',
    Firstname: firstname,
    lastname: lastname,
    Address: addr ? inputUserPayload?.unitNumber=='' ? addr :`Unit no : ${inputUserPayload?.unitNumber} , ` + addr : location.state?.formatted_address,
    city: inputUserPayload.city,
    Country: 'United States',
    State: 'Arkansas',
    zipcode:
      zipcode == ''
        ? location.state?.formatted_address.split(',')[1].split(' ')[2]
          ? location.state?.formatted_address.split(',')[1].split(' ')[2]
          : '00000'
        : zipcode,
    phonenumber: phone,
    emailaddress: email,
    howdidyouhear: 'facebook',
    preferredmethod: 'Contact field',
    besttimetocontact: 'NULL',
    comments: 'Hello',
    clientip: '39.57.229.116',
    Latitude: lat ? lat : location.state.geometry.location.lat,
    Longitude: lng ? lng : location.state.geometry.location.lng,
    key: 'ccbc108bca0110d59a9f9d05a3d53d33'
  })
  
  function handleNext () {
    if (cstepper5) {
      navigate('/')
      dispatch(resetState())
      return
    }
    if (agree) {
      toast.dismiss()
      setPleaseAgreeMsg(false)
      setLoading(true)

      var data2 = JSON.stringify({
        serviceType: parseInt(inputUserPayload?.serviceType) ,
        location: {
          line1: addr ? inputUserPayload?.unitNumber=='' ? addr :`Unit no : ${inputUserPayload?.unitNumber} , ` + addr : location.state?.formatted_address,
          city: inputUserPayload.city,
          zip:
            zipcode == ''
              ? location.state?.formatted_address.split(',')[1].split(' ')[2]
              : zipcode,
          latitude: lat ? lat : location.state.geometry.location.lat,
          longitude: lng ? lng : location.state.geometry.location.lng,
          country: 'US',
          subdiv: 'US_AR'
        },
        userinfo: {
          fullname: firstname + ' ' + lastname
        },
        packages: selectedPackagesIds,
        services: selectedServicesIds
      })

      var config = {
        method: 'post',
        url: '/flows/covered',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data2
      }

      axiosInstance(config)
        .then(function (response) {
          JSON.stringify(response.data)

          if (response.status == 200) {
            httpService
              .eusSubmission(data)
              .then(resp => {
                setLoading(false)

                // dispatch(setCstep5())
                dispatch(setcstepper5())
                toast.success('Requested submitted successfully', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
                })
              })
              .catch(err => {
                setLoading(false)
                console.log(err)
                toast.error('Something went wrong', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
                })
              })
          }
        })
        .catch(function (error) {
          if (error.response.status == 500) {
            setLoading(false)
            
            if (error.response.data.msg.includes('duplicate')) {
              toast.error('We are already providing service in this area', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              })
            } else {
              console.log(error)
              toast.error('Something went wrong', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              })
            }

            
          }
          setLoading(false)
        })
    } else {
      toast.error('Please check the terms and conditions checkbox', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    }
  }
  function handlePrevious () {
    toast.dismiss()
    dispatch(setCstep3())
    dispatch(setcstepper3())
  }

  return (
    <div
      className='panel panel-primary setup-content'
      id='step-4'
      style={{ ...styleE }}
    >
      <div className={`wrapper ${!loading ? 'd-none' : ''}`}>
        <div class='loader'>
          <div class='loader__part loader__part--1'></div>
          <div class='loader__part loader__part--2'></div>
          <div class='loader__part loader__part--3'></div>
        </div>
      </div>
      <div className='inner-upper-block'>
        <div className='panel-heading'>
          <h3 className='panel-title'>
            {' '}
            {
              content.filter(
                elem => elem.key == 'COVERED_SUBMIT_MAINHEADING'
              )[0]?.value
            }
          </h3>
        </div>

        {
          <p
            className='text-primary'
            style={{ visibility: cstepper5 ? 'hidden' : 'inherit' }}
          >
            {' '}
            {
              content.filter(elem => elem.key == 'COVERED_SUBMIT_PARA')[0]
                ?.value
            }{' '}
          </p>
        }
        <div className='panel-body'>
          <div className='row justify-content-center'>
            <div className='col-lg-6'>
              <div className='side-block p-4'>
                <h3 className='panel-title'> {
              content.filter(elem => elem.key == 'COVERED_SUBMIT_TABLE_HEADING')[0]
                ?.value
            }</h3>

                <table className='table table-condensed bg-white'>
                  <tbody>
                    {/* <tr>
                      <th>
                        {' '}
                        <strong> Service Area </strong>
                      </th>
                      <td> Install </td>
                    </tr> */}

                    <tr>
                      <th>
                        {' '}
                        <strong> {
              content.filter(elem => elem.key == 'COVERED_SUBMIT_TABLE_ROW_HEADING')[0]
                ?.value
            } </strong>
                      </th>
                      <td> {address} </td>
                    </tr>
                  </tbody>
                </table>

                <hr />
                <AmountTable
                  selectedPkgs={tabledata.selectedPkgs}
                  selectedServicesIds={tabledata.selectedServicesIds}
                  services={tabledata.services}
                  totalInstall={tabledata.totalInstall}
                  totalMonthly={tabledata.totalMonthly}
                />
              </div>

              <div className='form-check my-4'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  // value={agree}
                  defaultChecked={agree}
                  onChange={e => {
                    if (agree) {
                      setPleaseAgreeMsg(false)
                    }
                    dispatch(setAgree(!agree))
                  }}
                  id='flexCheckDefault'
                  disabled={cstepper5}
                />
                <label className='form-check-label' htmlFor='flexCheckDefault'>
                  {
                    content.filter(
                      elem => elem.key == 'COVERED_SUBMIT_AGREE_ONE'
                    )[0]?.value
                  }
                  {' '}
                  <a href='https://premierbroadband.com/terms-of-service/'  target="_blank">
                    {
                      content.filter(
                        elem => elem.key == 'COVERED_SUBMIT_AGREE_TWO'
                      )[0]?.value
                    }
                    
                  </a>
                </label>
              </div>
              {cstepper5 && (
                <div className='panel-heading'>
                  <h3 className='panel-title text-success'>
                    {' '}
                    {
                      content.filter(
                        elem => elem.key == 'COVERED_SUBMIT_THANKYOU_MSG'
                      )[0]?.value
                    }{' '}
                  </h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div class='_3uApM '>
        <a
          class='_2pGos _3CDiP  '
          onClick={handlePrevious}
          style={{ visibility: cstepper5 ? 'hidden' : 'inherit' }}
        >
          Previous
        </a>
        <a class='_2pGos _hsN1w ' onClick={handleNext}>
          {cstepper5 ? 'Go to home page' : 'Submit'}
        </a>
      </div>

      <ToastContainer toastStyle={{ position: 'fixed' }} />
    </div>
  )
}

export default Step4
