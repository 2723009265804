import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
function AmountTable ({
  selectedPkgs,
  services,
  selectedServicesIds,
  totalInstall,
  totalMonthly
}) {

  const content = useSelector(state => state.content)

  function insertDecimal (num) {
    return (parseFloat(num) / 100).toFixed(2)
  }
  
  return (
    <table className='table table-condensed'>
      <tbody>
        <tr>
          <th> Products</th>
          <th className='installment'> Install </th>
          <th> Monthly </th>
        </tr>

        {selectedPkgs.map((cval, index) => {
          return (
            <tr key={index}>
              <td> {cval.product}</td>
              <td>
                {' '}
                {cval.install == 0 ? '-' : '$' + parseFloat(cval.install).toFixed(2)}{' '}
              </td>
              <td> ${insertDecimal(cval.monthly)} </td>
            </tr>
          )
        })}
        {services
          .filter(elem => selectedServicesIds.includes(elem.id))
          .map((cval, index) => {
            return (
              <tr key={index}>
                <td> {cval.srv_name}</td>
                <td>
                  {' '}
                  {cval.install_amount == 0 || cval.install_amount == null
                    ? '-'
                    : '$' + parseFloat(cval.install_amount).toFixed(2)}
                </td>
                <td> ${insertDecimal(cval.amount)} </td>
              </tr>
            )
          })}
        <tr>
          <td>
            {' '}
            <strong> Total </strong>
          </td>
          <td>
            {' '}
            <strong>
              {totalInstall == 0 ? '-' : '$' + parseFloat(totalInstall).toFixed(2)}
            </strong>
          </td>
          <td>
            {' '}
            <strong> ${insertDecimal(totalMonthly)}</strong>{' '}
          </td>
        </tr>
        <tr>
          <td colSpan={3} className='text-success'>
            {' '}
            {content.filter(elem=>elem.key=='COVERED_SERVICE_TAXTITLE')[0]?.value} <br/>
            <span style={{color:'black', fontSize:'small'}}>
            {content.filter(elem=>elem.key=='COVERED_SERVICE_TAXSUBTITLE')[0]?.value}
            </span>
            
            {' '}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default AmountTable
