import React, { useState, useRef, useEffect } from 'react'
import {
  setNCstep1,
  setNCstep3,
  setncstepper1,
  setncstepper3,
  setLongitudeNC,
  setLatitudeNC,
  setNCAllInfoObj,
  setCstep2,
  setCstep3,
  setcstepper3
} from '../../../redux/Actions'
import { useSelector, useDispatch } from 'react-redux'
import { httpService } from '../../../services/PreQualification'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
var qs = require('qs')
function Step2({ address, styleE }) {
  const location = useLocation()
  let navigate = useNavigate()
  console.log(location.state)

  const lat = useSelector(state => state.latitudeNC)
  const lng = useSelector(state => state.longitudeNC)

  const addr = useSelector(state => state.address)

  const zipcode = useSelector(state => state.zip)
  const ucflag = useSelector(state => state.ucflag)

  const inputUserPayload = useSelector(state => state.inputUserPayload)

  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  const [loading, setLoading] = useState(false)

  const [fnameError, setFnameError] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [phoneError, setPhoneError] = useState(null)

  const myRef = useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView()

  const content = useSelector(state => state.content)

  const validateEmail = email => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }
  const validatePhone = phone => {
    var pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    return pattern.test(phone) // returns a boolean
  }

  const dispatch = useDispatch()
  function handleNext() {
    //add validation logic here

    let ferror = false,
      eerror = false,
      perror = false

    if (firstname == '') {
      setFnameError('Firstname is required')
      ferror = true
    } else if (!/^[a-zA-Z]+$/.test(firstname)) {
      setFnameError('Firstname can only contain alphabets')
      ferror = true
    } else {
      setFnameError(null)
    }

    if (email == '') {
      setEmailError('Email is required')
      eerror = true
    } else if (!validateEmail(email)) {
      setEmailError('Email not valid')
      eerror = true
    } else {
      setEmailError(null)
    }
    if (phone == '') {
      setPhoneError('Phone is required')
      perror = true
    } else if (!validatePhone(phone)) {
      setPhoneError('Phone not valid')
      perror = true
    } else {
      setPhoneError(null)
    }

    if (ferror || eerror || perror) {
      return
    }

    if (firstname == '' || email == '' || phone == '') {
      return
    }
    setEmailError(null)
    setFnameError(null)
    setPhoneError(null)
    const data = {
      multicoverageid: '52477',
      Account: '17152',
      Firstname: firstname,
      lastname: lastname,
      Address: addr ? inputUserPayload?.unitNumber == '' ? addr : `Unit no : ${inputUserPayload?.unitNumber} , ` + addr : location.state?.formatted_address,
      city: location.state?.formatted_address.split(',')[0],
      Country: 'United States',
      State: 'Arkansas',
      zipcode:
        zipcode == ''
          ? location.state?.formatted_address.split(',')[1].split(' ')[2]
            ? location.state?.formatted_address.split(',')[1].split(' ')[2]
            : '00000'
          : zipcode,
      phonenumber: phone,
      emailaddress: email,
      howdidyouhear: 'facebook',
      preferredmethod: 'Contact field',
      besttimetocontact: 'NULL',
      comments: 'Hello',
      clientip: '39.57.229.116',
      Latitude: lat ? lat : location.state.geometry.location.lat,
      Longitude: lng ? lng : location.state.geometry.location.lng,
      key: 'ccbc108bca0110d59a9f9d05a3d53d33'
    }

    ////
    dispatch(setNCAllInfoObj(data))
    ////

    dispatch(setNCstep3())
    dispatch(setncstepper3())
  }
  function handlePrevious() {
    navigate('/covered-location', { state: location.state })
    // dispatch(setNCstep1())
    // dispatch(setncstepper1())
  }

  // useEffect(()=>{
  //   executeScroll()
  // },[])

  useEffect(() => {
    const firstname_storage = localStorage.getItem('firstname_storage');
    const lastname_storage = localStorage.getItem('lastname_storage');
    const email_storage = localStorage.getItem('email_storage');
    const phone_storage = localStorage.getItem('phone_storage');
    if (firstname_storage) {
      setFirstname(firstname_storage);
    }
    if (lastname_storage) {
      setLastname(lastname_storage);
    }
    if (email_storage) {
      setEmail(email_storage);
    }
    if (phone_storage) {
      setPhone(phone_storage);
    }
  }, []);

  return (
    <div className='panel panel-primary setup-content' style={{ ...styleE }}>
      <div className={`wrapper ${!loading ? 'd-none' : ''}`}>
        <div class='loader'>
          <div class='loader__part loader__part--1'></div>
          <div class='loader__part loader__part--2'></div>
          <div class='loader__part loader__part--3'></div>
        </div>
      </div>
      <div ref={myRef}></div>
      <div className='inner-upper-block'>
        <h2>
          {ucflag
            ? content.filter(
              elem => elem.key == 'UNDERCONSTRUCTION_CONTACT_MAINHEADING'
            )[0]?.value
            : content.filter(
              elem => elem.key == 'NOTCOVERED_CONTACT_MAINHEADING'
            )[0]?.value}{' '}
        </h2>

        <div className='heading'>
          {/* <p>
            We are planning on bringing fiber internet to your area but are not
            ready for in-home installations at this time. Please take a moment
            to provide us with your contact information so we can contact you
            when we get closer to in home installations{' '}
          </p> */}
          <p>
            {ucflag
              ? content.filter(
                elem => elem.key == 'UNDERCONSTRUCTION_CONTACT_SUBHEADING'
              )[0]?.value
              : content.filter(
                elem => elem.key == 'NOTCOVERED_CONTACT_SUBHEADING'
              )[0]?.value}
          </p>
          {
            (ucflag ?
            content.filter(
              elem => elem.key == 'UNDERCONSTRUCTION_CONTACT_LINE1'
            )[0]?.value != '' ? true:false : content.filter(
              elem => elem.key == 'NOTCOVERED_CONTACT_LINE1'
            )[0]?.value != '' ? true : false) &&
            (<>
              <p>
                {
                  ucflag
                    ? content.filter(
                      elem => elem.key == 'UNDERCONSTRUCTION_CONTACT_LINE1'
                    )[0]?.value
                    : content.filter(
                      elem => elem.key == 'NOTCOVERED_CONTACT_LINE1'
                    )[0]?.value}
              </p>
            </>)}
            {
              (ucflag ?
            content.filter(
              elem => elem.key == 'UNDERCONSTRUCTION_CONTACT_LINE2'
            )[0]?.value != '' ? true:false : content.filter(
              elem => elem.key == 'NOTCOVERED_CONTACT_LINE2'
            )[0]?.value != '' ? true : false) &&
            (<>
              <p>
                {
                  ucflag
                    ? content.filter(
                      elem => elem.key == 'UNDERCONSTRUCTION_CONTACT_LINE2'
                    )[0]?.value
                    : content.filter(
                      elem => elem.key == 'NOTCOVERED_CONTACT_LINE2'
                    )[0]?.value}
              </p>
            </>)}
            {
              (ucflag ?
           content.filter(
              elem => elem.key == 'UNDERCONSTRUCTION_CONTACT_LINE3'
            )[0]?.value != '' ? true:false : content.filter(
              elem => elem.key == 'NOTCOVERED_CONTACT_LINE3'
            )[0]?.value != '' ? true : false) &&
           ( <>
              <p>
                {
                  ucflag
                    ? content.filter(
                      elem => elem.key == 'UNDERCONSTRUCTION_CONTACT_LINE3'
                    )[0]?.value
                    : content.filter(
                      elem => elem.key == 'NOTCOVERED_CONTACT_LINE3'
                    )[0]?.value}
              </p>

            </>)}
            {
              (ucflag ?
            content.filter(
              elem => elem.key == 'UNDERCONSTRUCTION_CONTACT_LINE4'
            )[0]?.value != '' ? true:false : content.filter(
              elem => elem.key == 'NOTCOVERED_CONTACT_LINE4'
            )[0]?.value != '' ? true : false) &&
            (<>
              <p>
                {
                  ucflag
                    ? content.filter(
                      elem => elem.key == 'UNDERCONSTRUCTION_CONTACT_LINE4'
                    )[0]?.value
                    : content.filter(
                      elem => elem.key == 'NOTCOVERED_CONTACT_LINE4'
                    )[0]?.value}
              </p>
             
            </>)}
        </div>
        <div className='contact-info mt-4'>
          <h2>
            {
              ucflag ?
                content.filter(
                  elem => elem.key == 'UNDERCONSTRUCTION_CONTACT_FORMHEADING'
                )[0]?.value
                :
                content.filter(
                  elem => elem.key == 'NOTCOVERED_CONTACT_FORMHEADING'
                )[0]?.value
            }
          </h2>
          <div className='email-address'>
            <form className='text-start'>
              <div className='row'>
                <div className='col-lg-6 mb-3'>
                  <label
                    htmlFor='inputPassword6'
                    className='col-form-label text-start w-100'
                  >
                    {
                      ucflag ?
                        content.filter(
                          elem =>
                            elem.key == 'UNDERCONSTRUCTION_CONTACT_FORMFIELD_FNAMELABEL'
                        )[0]?.value
                        :
                        content.filter(
                          elem =>
                            elem.key == 'NOTCOVERED_CONTACT_FORMFIELD_FNAMELABEL'
                        )[0]?.value
                    }
                    <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type='text'
                    placeholder='First Name'
                    id='inputPassword6'
                    className='form-control'
                    aria-describedby='passwordHelpInline'
                    onChange={e => {
                      setFirstname(e.target.value.replace(/[^a-z]/gi, ''));
                      localStorage.setItem('firstname_storage', e.target.value.replace(/[^a-z]/gi, ''));
                    }}
                    value={firstname}
                    maxLength={50}
                  />
                  {fnameError ? (
                    <span className='d-block' style={{ color: 'red' }}>
                      {fnameError}
                    </span>
                  ) : (
                    ''
                  )}
                </div>

                <div className='col-lg-6 mb-3'>
                  <label
                    htmlFor='inputPassword6'
                    className='col-form-label text-start w-100'
                  >
                    {
                      ucflag ?
                        content.filter(
                          elem =>
                            elem.key == 'UNDERCONSTRUCTION_CONTACT_FORMFIELD_LNAMELABEL'
                        )[0]?.value
                        :
                        content.filter(
                          elem =>
                            elem.key == 'NOTCOVERED_CONTACT_FORMFIELD_LNAMELABEL'
                        )[0]?.value
                    }
                  </label>
                  <input
                    type='text'
                    id='inputPassword6'
                    placeholder='Last Name'
                    className='form-control'
                    aria-describedby='passwordHelpInline'
                    onChange={e => {
                      setLastname(e.target.value.replace(/[^a-z]/gi, ''));
                      localStorage.setItem('lastname_storage', e.target.value.replace(/[^a-z]/gi, ''));
                    }}
                    value={lastname}
                    maxLength={50}
                  />
                </div>
                <div className='col-lg-6 '>
                  <label
                    htmlFor='exampleInputEmail1'
                    className='form-label text-start w-100'
                  >
                    {
                      ucflag ?
                        content.filter(
                          elem =>
                            elem.key == 'UNDERCONSTRUCTION_CONTACT_FORMFIELD_EMAILLABEL'
                        )[0]?.value
                        :
                        content.filter(
                          elem =>
                            elem.key == 'NOTCOVERED_CONTACT_FORMFIELD_EMAILLABEL'
                        )[0]?.value
                    }{' '}
                    <span style={{ color: 'red' }}>*</span>
                  </label>

                  <input
                    type='email'
                    placeholder='Email Address'
                    className='form-control'
                    id='exampleInputEmail1'
                    aria-describedby='emailHelp'
                    onChange={e => {
                      setEmail(e.target.value);
                      localStorage.setItem('email_storage', e.target.value);

                    }}
                    value={email}
                  />
                  {emailError ? (
                    <span className='d-block' style={{ color: 'red' }}>
                      {emailError}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className='col-lg-6 '>
                  <label
                    htmlFor='inputPassword6'
                    className='col-form-label w-100 text-start'
                  >
                    {
                      ucflag ?
                        content.filter(
                          elem =>
                            elem.key == 'UNDERCONSTRUCTION_CONTACT_FORMFIELD_PHONELABEL'
                        )[0]?.value
                        :
                        content.filter(
                          elem =>
                            elem.key == 'NOTCOVERED_CONTACT_FORMFIELD_PHONELABEL'
                        )[0]?.value
                    }
                    <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type='tel'
                    placeholder='Mobile Number'
                    id='inputPassword6'
                    className='form-control'
                    aria-describedby='passwordHelpInline'
                    onChange={e => {
                      setPhone(e.target.value.replace(/[^0-9]/g, ''));
                      localStorage.setItem('phone_storage', e.target.value.replace(/[^0-9]/g, ''));
                    }}
                    value={phone}
                  />
                  {phoneError ? (
                    <span className='d-block' style={{ color: 'red' }}>
                      {phoneError}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class='_3uApM '>
        <a class='_2pGos _3CDiP  ' onClick={handlePrevious}>
          Previous
        </a>
        {/* <a class='_2pGos _3CDiP _2RWmX '>Previous</a> */}
        <a onClick={handleNext} class='_2pGos _hsN1w '>
          Next
        </a>
      </div>
      <ToastContainer toastStyle={{ position: 'fixed' }} />
    </div>
  )
}

export default Step2
