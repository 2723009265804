import axiosInstance from '../../axios/index_backend'
import { useState, useEffect } from 'react'
import AmountTable from '../AmountTable'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAmountTableData,
  setCstep2,
  setCstep4,
  setCstep5,
  setcstepper2,
  setcstepper4,
  setcstepper5,
  setSelectedPackages,
  setSelectedServices
} from '../../redux/Actions'
import { ToastContainer, toast } from 'react-toastify'
import ServiceCard from '../ServiceCard'
import PackageCard from '../PackageCard'

import { httpService } from '../../services/PreQualification'
import { useLocation } from 'react-router-dom'
var qs = require('qs')

function Step3 ({ address, styleE }) {
  const dispatch = useDispatch()

  const [packages, setPackages] = useState([])
  const [services, setServices] = useState([])

  const [singleSelectedPkgId, setSingleSelectedPkgId] = useState(null)
  const [selectedPkgs, setSelectedPkgs] = useState([])

  const [selectedServicesIds, setSelectedServicesIds] = useState([])

  const [totalInstall, setTotalInstall] = useState(0)
  const [totalMonthly, setTotalMonthly] = useState(0)

  const [pleaseSelectPkgMsg, setPleaseSelectPkgMsg] = useState(false)

  const [loadingPkgs, setLoadingPkgs] = useState(false)
  const [loadingServs, setLoadingServs] = useState(false)

  const showStep3ErrToastContainer = useSelector(
    state => state.showStep3ErrToastContainer
  )

  const inputUserPayload = useSelector(state => state.inputUserPayload)

  function getPackages () {
    const config = {
      method: 'get',
      url: `/package/list/userfacing?pageNo=1&itemsPerPage=100&zipcode=${inputUserPayload?.zipcode}&type=${inputUserPayload?.serviceType}`
    }
    setLoadingPkgs(true)
    axiosInstance(config)
      .then(function (response) {
        setPackages(response.data.data.result)
        setLoadingPkgs(false)
      })
      .catch(function (error) {
        setLoadingPkgs(false)
        console.log(error)
      })
  }

  function getServices () {
    const config = {
      method: 'get',
      url: `/service/list/userfacing?pageNo=1&itemsPerPage=100&zipcode=${inputUserPayload?.zipcode}&type=${inputUserPayload?.serviceType}`
    }
    setLoadingServs(true)
    axiosInstance(config)
      .then(function (response) {
        setServices(response.data.data.result)
        setLoadingServs(false)
      })
      .catch(function (error) {
        console.log(error)
        setLoadingServs(false)
      })
  }

  function toggleSelected (pkg) {
    if (singleSelectedPkgId == pkg.id) {
      setSingleSelectedPkgId(null)
      setSelectedPkgs([])
      return
    }
    setPleaseSelectPkgMsg(false)
    setSingleSelectedPkgId(pkg.id)
    setSelectedPkgs([
      {
        product: pkg.pkg_name,
        install:
          pkg.install_amount == null || pkg.install_amount == 0
            ? 0
            : parseFloat(pkg.install_amount),
        monthly: parseFloat(pkg.totalAmount)
      }
    ])
  }

  function toggleSelectedServices (serv) {
    if (selectedServicesIds.includes(serv.id)) {
      let fileteredIds = selectedServicesIds.filter(val => val != serv.id)
      setSelectedServicesIds(fileteredIds)
    } else {
      setSelectedServicesIds([...selectedServicesIds, serv.id])
    }
  }

  function isIdInSelectedServicesIds (id) {
    if (selectedServicesIds.includes(id)) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    getPackages()
    getServices()
  }, [])

  useEffect(() => {
    let tinstallp = 0
    let tmonthlyp = 0
    let tinstalls = 0
    let tmonthlys = 0
    selectedPkgs.map(val => {
      tinstallp += parseFloat(val.install)
      tmonthlyp += parseFloat(val.monthly)
    })
    services
      .filter(elem => selectedServicesIds.includes(elem.id))
      .map(val => {
        tinstalls +=
          val.install_amount == null || val.install_amount == 0
            ? 0
            : parseFloat(val.install_amount)
        tmonthlys += parseFloat(val.amount)
      })

    setTotalInstall(tinstallp + tinstalls)
    setTotalMonthly(tmonthlyp + tmonthlys)
  })

  // function handleNext () {
    
  //   dispatch(setCstep5())
  //   dispatch(setcstepper5())
  // }
  function handlePrevious () {
    toast.dismiss()
    dispatch(setCstep2())
    dispatch(setcstepper2())
  }
  const location = useLocation()
  const firstname = useSelector(state => state.firstname)
  const lastname = useSelector(state => state.lastname)
  const email = useSelector(state => state.email)
  const phone = useSelector(state => state.phone)
  const zipcode = useSelector(state => state.zip)
  const tabledata = useSelector(state => state.obj)
  const agree = useSelector(state => state.agree)

  const lat = useSelector(state => state.latitude)
  const lng = useSelector(state => state.longitude)

  const addr = useSelector(state => state.address)

  const content = useSelector(state => state.content)

  const [pleaseAgreeMsg, setPleaseAgreeMsg] = useState(false)

  const [loading, setLoading] = useState(false)

  const selectedPackagesIds = useSelector(state => state.selectedPackagesIds)

  

  const data = qs.stringify({
    multicoverageid: '52477',
    Account: '17152',
    Firstname: firstname,
    lastname: lastname,
    Address: addr ? addr : location.state?.formatted_address,
    city: inputUserPayload?.city,
    Country: 'United States',
    State: 'Arkansas',
    zipcode:
      zipcode == ''
        ? location.state?.formatted_address.split(',')[1].split(' ')[2]
          ? location.state?.formatted_address.split(',')[1].split(' ')[2]
          : '00000'
        : zipcode,
    phonenumber: phone,
    emailaddress: email,
    howdidyouhear: 'facebook',
    preferredmethod: 'Contact field',
    besttimetocontact: 'NULL',
    comments: 'Hello',
    clientip: '39.57.229.116',
    Latitude: lat ? lat : location.state.geometry.location.lat,
    Longitude: lng ? lng : location.state.geometry.location.lng,
    key: 'ccbc108bca0110d59a9f9d05a3d53d33'
  })
  
  function handleNext () {
    if (selectedPkgs.length == 0) {
      toast.error('Please select atleast one package', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
      return
    }

    toast.dismiss()
    dispatch(
      setAmountTableData({
        selectedPkgs,
        selectedServicesIds,
        services,
        totalInstall,
        totalMonthly
      })
    )

    dispatch(setSelectedPackages([singleSelectedPkgId]))
    dispatch(setSelectedServices(selectedServicesIds))
    
    dispatch(setCstep4())
    dispatch(setcstepper4())
  }
  return (
    <div
      className='panel panel-primary setup-content'
      id='step-3'
      style={{ ...styleE }}
    >
      <div
        className={`wrapper ${!loading ? 'd-none' : ''}`}
      >
        <div class='loader'>
          <div class='loader__part loader__part--1'></div>
          <div class='loader__part loader__part--2'></div>
          <div class='loader__part loader__part--3'></div>
        </div>
      </div>
      <div className='address-block'>
        <strong> Your Address: </strong> {address}{' '}
        {/* <strong> Service Area: </strong> Remote */}
      </div>
      <div className='inner-upper-block'>
        <div className='row mt-3'>
          <div className='col-lg-9'>
            <div className='panel-heading'>
              <h3 className='panel-title'>{content.filter(elem=>elem.key=='COVERED_SERVICE_MAINHEADING')[0]?.value} </h3>
            </div>
            <p>
              {' '}
              {content.filter(elem=>elem.key=='COVERED_SERVICE_SUBHEADING')[0]?.value}{' '}
            </p>
            <hr />
            <h3 className='page-section-title'>
              {inputUserPayload?.serviceType == 1
                ? 'Residential Internet'
                : 'Commercial Internet'}{' '}
            </h3>
            <div className='panel-body'>
              <div className='row'>
                {packages?.map((cval, index) => {
                  return (
                    <PackageCard
                      index={index}
                      cval={cval}
                      toggleSelected={toggleSelected}
                      singleSelectedPkgId={singleSelectedPkgId}
                    />
                  )
                })}
                {packages.length == 0 && <span>No packages available</span>}
              </div>
              <hr />
              <h3 className='page-section-title text-warning'>
                {/* Residential  */}
                {content.filter(elem=>elem.key=='COVERED_SERVICE_SUBHEADING_TWO')[0]?.value}{' '}
              </h3>
              <div className='row'>
                {services.map((serv, index) => {
                  return (
                    <ServiceCard
                      index={index}
                      serv={serv}
                      toggleSelectedServices={toggleSelectedServices}
                      isIdInSelectedServicesIds={isIdInSelectedServicesIds}
                    />
                  )
                })}
                {services.length == 0 && <span>No add-ons available</span>}
              </div>
            </div>
          </div>
          <div className='col-lg-3 side-block  '>
            <div className='side-block'>
              <AmountTable
                selectedPkgs={selectedPkgs}
                selectedServicesIds={selectedServicesIds}
                services={services}
                totalInstall={totalInstall}
                totalMonthly={totalMonthly}
              />
            </div>
          </div>
        </div>
      </div>
      <div class='_3uApM '>
        <a class='_2pGos _3CDiP  ' onClick={handlePrevious}>
          Previous
        </a>
        <a class='_2pGos _hsN1w ' onClick={handleNext}>
          Next
        </a>
      </div>

      <ToastContainer
        toastStyle={{
          position: 'fixed'
        }}
      />
    </div>
  )
}
export default Step3
