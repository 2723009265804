import React, { useEffect } from 'react'
import { Route, Redirect, Routes } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import './App.css'
import Home from './components/Home/Home'
import Search from './components/Home/Search'
import CoveredLocation from './components/Steps-Vizard/CoveredLocation'
import NotCoveredLocation from './components/Steps-Vizard/NotCoveredLocation'

import axiosInstance from './axios/index_backend'
import { setContent } from './redux/Actions'
import { getContentByKey } from './commons'
function App () {


  const dispatch = useDispatch()

  useEffect(() => {
    var config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: '/content/allkeyvalues',
      headers: {}
    }

    axiosInstance(config)
      .then(function (response) {
        dispatch(setContent(response.data.data))
        
        
      })
      .catch(function (error) {
        
      })
  }, [])

  return (
    <Routes>
      {/* <Switch> */}
      <Route exact path='/' element={<Home />} />
      <Route path='/covered-location' element={<CoveredLocation />} />
      <Route path='/not-covered-location' element={<NotCoveredLocation />} />
      <Route path='/search' element={<Search />} />
      {/* </Switch> */}
    </Routes>
  )
}

export default App
