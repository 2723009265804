const inititalState = {
  obj: {},
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  zip: '',
  latitude: '',
  longitude: '',
  latitudeNC: '',
  longitudeNC: '',
  cstep1: true,
  cstep2: false,
  cstep3: false,
  cstep4: false,
  cstep5: false,
  agree: false,
  ncstep1: false,
  ncstep2: true,
  ncstep3: false,
  ncstep4: false,
  cstepper1: 'active',
  cstepper2: '',
  cstepper3: '',
  cstepper4: '',
  cstepper5: '',
  selectedPackagesIds: [],
  selectedServicesIds: [],
  ncstepper1: 'done',
  ncstepper2: 'active',
  ncstepper3: '',
  address: '',
  newaddr:'',
  inputUserPayload: null,
  content: [],
  ucflag:false,
  ncAllInfoObj: {}
}
const myReducer = (state = inititalState, action) => {
  switch (action.type) {
    case 'SET_AMOUNT_TABLE_DATA': {
      return { ...state, obj: action.payload }
    }
    case 'SET_USER_FIRSTNAME': {
      return { ...state, firstname: action.payload }
    }
    case 'SET_USER_LASTNAME': {
      return { ...state, lastname: action.payload }
    }
    case 'SET_USER_EMAIL': {
      return { ...state, email: action.payload }
    }
    case 'SET_USER_PHONE': {
      return { ...state, phone: action.payload }
    }
    case 'SET_ZIP': {
      return { ...state, zip: action.payload }
    }
    case 'SET_LAT': {
      return { ...state, latitude: action.payload }
    }
    case 'SET_LONG': {
      return { ...state, longitude: action.payload }
    }
    case 'SET_CSTEP1': {
      return {
        ...state,
        cstep1: true,
        cstep2: false,
        cstep3: false,
        cstep4: false,
        cstep5: false
      }
    }
    case 'SET_CSTEP2': {
      return {
        ...state,
        cstep1: false,
        cstep2: true,
        cstep3: false,
        cstep4: false,
        cstep5: false
      }
    }
    case 'SET_CSTEP3': {
      return {
        ...state,
        cstep1: false,
        cstep2: false,
        cstep3: true,
        cstep4: false,
        cstep5: false
      }
    }
    case 'SET_CSTEP4': {
      return {
        ...state,
        cstep1: false,
        cstep2: false,
        cstep3: false,
        cstep4: true,
        cstep5: false
      }
    }
    case 'SET_CSTEP5': {
      return {
        ...state,
        cstep1: false,
        cstep2: false,
        cstep3: false,
        cstep4: false,
        cstep5: true
      }
    }
    case 'SET_AGREE': {
      return {
        ...state,
        agree: action.payload
      }
    }
    case 'SET_NCSTEP1': {
      return {
        ...state,
        ncstep1: true,
        ncstep2: false,
        ncstep3: false
      }
    }
    case 'SET_NCSTEP2': {
      return {
        ...state,
        ncstep1: false,
        ncstep2: true,
        ncstep3: false
      }
    }
    case 'SET_NCSTEP3': {
      return {
        ...state,
        ncstep1: false,
        ncstep2: false,
        ncstep3: true
      }
    }
    case 'SET_NCSTEP4': {
      return {
        ...state,
        ncstep1: false,
        ncstep2: false,
        ncstep3: true,
        ncstep4: true
      }
    }
    case 'SET_CSTEPPER1': {
      return {
        ...state,
        cstepper1: 'active',
        cstepper2: '',
        cstepper3: '',
        cstepper4: '',
        cstepper5: ''
      }
    }
    case 'SET_CSTEPPER2': {
      return {
        ...state,
        cstepper1: 'done',
        cstepper2: 'active',
        cstepper3: '',
        cstepper4: '',
        cstepper5: ''
      }
    }
    case 'SET_CSTEPPER3': {
      return {
        ...state,
        cstepper1: 'done',
        cstepper2: 'done',
        cstepper3: 'active',
        cstepper4: '',
        cstepper5: ''
      }
    }
    case 'SET_CSTEPPER4': {
      return {
        ...state,
        cstepper1: 'done',
        cstepper2: 'done',
        cstepper3: 'done',
        cstepper4: 'active',
        cstepper5: ''
      }
    }
    case 'SET_CSTEPPER5': {
      return {
        ...state,
        cstepper1: 'done',
        cstepper2: 'done',
        cstepper3: 'done',
        cstepper4: 'done',
        cstepper5: 'done'
      }
    }
    case 'SET_SELECTED_PKGS': {
      return {
        ...state,
        selectedPackagesIds: action.payload
      }
    }
    case 'SET_SELECTED_SERVICES': {
      return {
        ...state,
        selectedServicesIds: action.payload
      }
    }
    case 'SET_NCSTEPPER1': {
      return {
        ...state,
        ncstepper1: 'active',
        ncstepper2: '',
        ncstepper3: ''
      }
    }
    case 'SET_NCSTEPPER2': {
      return {
        ...state,
        ncstepper1: 'done',
        ncstepper2: 'active',
        ncstepper3: ''
      }
    }
    case 'SET_NCSTEPPER3': {
      return {
        ...state,
        ncstepper1: 'done',
        ncstepper2: 'done',
        ncstepper3: 'active'
      }
    }
    case 'SET_NCSTEPPER4': {
      return {
        ...state,
        ncstepper1: 'done',
        ncstepper2: 'done',
        ncstepper3: 'done'
      }
    }
    case 'SET_ADDRESS': {
      return {
        ...state,
        address: action.payload
      }
    }
    case 'SET_INPUT_USER_PAYLOAD': {
      return {
        ...state,
        inputUserPayload: action.payload
      }
    }
    case 'SET_LAT_NC': {
      return { ...state, latitudeNC: action.payload }
    }
    case 'SET_LONG_NC': {
      return { ...state, longitudeNC: action.payload }
    }
    case 'RESET_STATE': {
      return { ...inititalState }
    }
    case 'NEW_ADDR':{
      return {
        ...state,
        newaddr: action.payload
      }
    }
    case 'CONTENT':{
      return {
        ...state,
        content: action.payload
      }
    }
    case 'UC_FLAG':{
      return {
        ...state,
        ucflag: action.payload
      }
    }
    case 'SET_NC_ALL_INFO_OBJ':{
      return {
        ...state,
        ncAllInfoObj: action.payload
      }
    }
    
  }
  return state
}
export default myReducer
