import React, { useEffect, useState } from 'react'
import {
  resetState,
  setNCstep2,
  setNCstep3,
  setNCstep4,
  setncstepper2,
  setncstepper3,
  setNCstepper4,
  setAddressR
} from '../../../redux/Actions'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { httpService } from '../../../services/PreQualification'
import AmountTable from '../../AmountTable'
import { ToastContainer, toast } from 'react-toastify'

import axios from 'axios'
import {
  GOOGLE_MAP_API_BASE_URL,
  GOOGLE_MAP_API_KEY_URL,
  GOOGLE_MAP_API_KEY
} from '../../../constants/apiConstants'

var qs = require('qs')
function Step3 ({ address, styleE }) {
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const content = useSelector(state => state.content)
  console.log(content)
  // const ncAllInfoObj = useSelector(state => state.ncAllInfoObj)
  // console.log(ncAllInfoObj)
  const dispatch = useDispatch()
  let navigate = useNavigate()
 
  const lat = useSelector(state => state.latitude)
  const lng = useSelector(state => state.longitude)

  const ncAllInfoObj = useSelector(state => state.ncAllInfoObj)
  
  const ncstep4 = useSelector(state => state.ncstep4)

  const ucflag = useSelector(state => state.ucflag)
  const addr = useSelector(state => state.address)

  let [addresss, setAddress] = useState('')

  async function checkAdressAgain () {
    if (lat && lng) {
      var res = await axios.post(
        `${GOOGLE_MAP_API_BASE_URL}/json?latlng=${lat},${lng}&key=${GOOGLE_MAP_API_KEY_URL}`
      )

      dispatch(setAddressR(res?.data?.results[0]?.formatted_address))
      setAddress(res?.data?.results[0]?.formatted_address)
    }
  }

  useEffect(() => {
    checkAdressAgain()
  }, [])

  function handleNext () {
    if (!ncstep4) {
      const data = qs.stringify(ncAllInfoObj)

      
      setLoading(true)
      httpService
        .eusSubmission(data)
        .then(resp => {
          setLoading(false)
          toast.success('Requested submitted successfully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          })
          localStorage.clear();
          dispatch(setNCstep4())
          dispatch(setNCstepper4())
        })
        .catch(err => {
          setLoading(false)
          console.log(err)
          toast.error('Something went wrong', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          })
        })
    } else {
      navigate('/')
      dispatch(resetState())
    }
  }
  function handlePrevious () {
    dispatch(setNCstep2())
    dispatch(setncstepper2())
  }
  // useEffect(() => {
  //   setTimeout(() => {
  //     navigate('/')
  //     dispatch(resetState())
  //   }, 5000)
  // }, [])

  return (
    <div className='panel panel-primary setup-content' style={{ ...styleE }}>
      <div className={`wrapper ${!loading ? 'd-none' : ''}`}>
        <div class='loader'>
          <div class='loader__part loader__part--1'></div>
          <div class='loader__part loader__part--2'></div>
          <div class='loader__part loader__part--3'></div>
        </div>
      </div>
      <div className='inner-upper-block'>
        <h2>
          {ucflag
            ? content.filter(
                elem => elem.key == 'UNDERCONSTRUCTION_SUBMIT_MAINHEADING'
              )[0]?.value
            : content.filter(
                elem => elem.key == 'NOTCOVERED_SUBMIT_MAINHEADING'
              )[0]?.value}{' '}
        </h2>
        <div className='heading'>
          <p>
            {ucflag
              ? content.filter(
                  elem => elem.key == 'UNDERCONSTRUCTION_SUBMIT_PARA'
                )[0]?.value
              : content.filter(elem => elem.key == 'NOTCOVERED_SUBMIT_PARA')[0]
                  ?.value}{' '}
          </p>
        </div>
        <div className='panel-body'>
          <div className='row justify-content-center'>
            <div className='col-lg-6'>
              <div className='side-block p-4'>
                <h3 className='panel-title'>
                  {ucflag
                    ? content.filter(
                        elem =>
                          elem.key == 'UNDERCONSTRUCTION_SUBMIT_TABLE_HEADING'
                      )[0]?.value
                    : content.filter(
                        elem => elem.key == 'NOTCOVERED_SUBMIT_TABLE_HEADING'
                      )[0]?.value}
                </h3>

                <table className='table table-condensed bg-white'>
                  <tbody>
                    {/* <tr>
                      <th>
                        {' '}
                        <strong> Service Area </strong>
                      </th>
                      <td> Install </td>
                    </tr> */}
                    <tr>
                      <th>
                        {' '}
                        <strong>
                          {ucflag
                            ? content.filter(
                                elem =>
                                  elem.key ==
                                  'UNDERCONSTRUCTION_CONTACT_FORMFIELD_FNAMELABEL'
                              )[0]?.value
                            : content.filter(
                                elem =>
                                  elem.key ==
                                  'NOTCOVERED_CONTACT_FORMFIELD_FNAMELABEL'
                              )[0]?.value}
                        </strong>
                      </th>
                      <td> {ncAllInfoObj.Firstname} </td>
                    </tr>
                    <tr>
                      <th>
                        {' '}
                        <strong>
                          {ucflag
                            ? content.filter(
                                elem =>
                                  elem.key ==
                                  'UNDERCONSTRUCTION_CONTACT_FORMFIELD_LNAMELABEL'
                              )[0]?.value
                            : content.filter(
                                elem =>
                                  elem.key ==
                                  'NOTCOVERED_CONTACT_FORMFIELD_LNAMELABEL'
                              )[0]?.value}
                        </strong>
                      </th>
                      <td> {ncAllInfoObj.lastname} </td>
                    </tr>
                    <tr>
                      <th>
                        {' '}
                        <strong>
                          {ucflag
                            ? content.filter(
                                elem =>
                                  elem.key ==
                                  'UNDERCONSTRUCTION_CONTACT_FORMFIELD_PHONELABEL'
                              )[0]?.value
                            : content.filter(
                                elem =>
                                  elem.key ==
                                  'NOTCOVERED_CONTACT_FORMFIELD_PHONELABEL'
                              )[0]?.value}
                        </strong>
                      </th>
                      <td> {ncAllInfoObj.phonenumber} </td>
                    </tr>
                    <tr>
                      <th>
                        {' '}
                        <strong>
                          {ucflag
                            ? content.filter(
                                elem =>
                                  elem.key ==
                                  'UNDERCONSTRUCTION_CONTACT_FORMFIELD_EMAILLABEL'
                              )[0]?.value
                            : content.filter(
                                elem =>
                                  elem.key ==
                                  'NOTCOVERED_CONTACT_FORMFIELD_EMAILLABEL'
                              )[0]?.value}
                        </strong>
                      </th>
                      <td> {ncAllInfoObj.emailaddress} </td>
                    </tr>
                    <tr>
                      <th>
                        {' '}
                        <strong>
                          {ucflag
                            ? content.filter(
                                elem =>
                                  elem.key ==
                                  'UNDERCONSTRUCTION_SUBMIT_TABLE_ROW_HEADING'
                              )[0]?.value
                            : content.filter(
                                elem =>
                                  elem.key ==
                                  'NOTCOVERED_SUBMIT_TABLE_ROW_HEADING'
                              )[0]?.value}
                        </strong>
                      </th>
                      <td> {addresss ? addresss : address ? address : addr} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {ncstep4 && (
                <div className='panel-heading mt-2'>
                  <h3 className='panel-title text-success'>
                    {' '}
                    {ucflag
                      ? content.filter(
                          elem =>
                            elem.key == 'UNDERCONSTRUCTION_SUBMIT_THANKYOU_MSG'
                        )[0]?.value
                      : content.filter(
                          elem => elem.key == 'NOTCOVERED_SUBMIT_THANKYOU_MSG'
                        )[0]?.value}{' '}
                  </h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <br />

      <div class='_3uApM '>
        <a
          class='_2pGos _3CDiP  '
          onClick={handlePrevious}
          style={{ visibility: ncstep4 ? 'hidden' : 'block' }}
        >
          Previous
        </a>
        {/* <a class='_2pGos _3CDiP  ' onClick={handlePrevious}>
          Previous
        </a> */}
        <a onClick={handleNext} class='_2pGos _hsN1w '>
          {!ncstep4 ? 'Submit' : 'Go to main page'}
        </a>
      </div>
      <ToastContainer toastStyle={{ position: 'fixed' }} />
    </div>
  )
}

export default Step3
