import React, { useState, useEffect } from 'react'
import layout from './layout.css'
import axios from 'axios'
import axiosInstance from '../../axios/index_backend'
import { useSearchParams } from 'react-router-dom';
import {
  GOOGLE_MAP_API_BASE_URL,
  GOOGLE_MAP_API_KEY_URL,
  GOOGLE_MAP_API_KEY
} from '../../constants/apiConstants'

import { httpService } from '../../services/PreQualification'

import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Oval } from 'react-loader-spinner'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAddressR,
  setCstep1,
  setcstepper2,
  setZipAction,
  resetState,
  setInputUserPayload,
  setContent
} from '../../redux/Actions'
var qs = require('qs')
const Search = () => {
const [searchParams] = useSearchParams();
  const location = useLocation()

  
  // {zipcode: '71801', serviceType: '1'}

  const [loading, setLoading] = useState(false)
  const [multicoverageid, setMultiCoverageId] = useState('52477')
  const [account, setAccount] = useState('17152')
  const [firstname, setFirstname] = useState('Hunain')
  const [lastname, setLastname] = useState('Arif')
  const [address, setAddress] = useState(
    searchParams.get('address')/* !=undefined? useSearchParams('address'):location.state ? location.state.address : '' */
  )
  const [city, setCity] = useState(searchParams.get('city')/* location.state ? location.state.city : '' */)
  const [country, setCountry] = useState('Hempstead County')
  const [state, setState] = useState('Arkansas')
  const [zipcode, setZip] = useState(
    searchParams.get('zipcode')/* !=undefined? useSearchParams('zipcode'):location.state ? location.state.zipcode : '' */
  )
  const [phonenumber, setPhoneNumber] = useState('+1')
  const [emailaddress, setEmailAddress] = useState(
    'ajay.kumar@icon-solutions.us'
  )
  const [howdidyouhear, setHowDidYouHear] = useState('facebook')
  const [preferredmethod, setPreferredMethod] = useState('Contact field')
  const [besttimetocontact, setBestTimeToContact] = useState(null)
  const [comments, setComments] = useState('hello')
  const [clientip, setClientIP] = useState('39.57.229.116')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [rxmargin, setRxMargin] = useState('')
  const [result, setResult] = useState('')

  const [unitNumber, setUnitNumber] = useState(
    searchParams.get('unit')/* !=undefined? useSearchParams('unit'):location.state ? location.state.unitNumber : '' */
  )
  const [serviceType, setServiceType] = useState(
    searchParams.get('type')/* !=undefined? useSearchParams('type'): location.state ? location.state.serviceType : '' */
  )

  const dispatch = useDispatch()

  let navigate = useNavigate()

  const content = useSelector(state => state.content)

  const urlInitCall = async () => {
   
   
    if (address !== '' || zipcode !== '' || city!=='') setLoading(true)



    let editedAddr = address

    if(!editedAddr.toLowerCase().includes(city.toLowerCase())){
      editedAddr = editedAddr + ' ' + city + ' '
    }

  
  

    dispatch(setInputUserPayload({ address, zipcode, serviceType, city, unitNumber }))

    dispatch(setAddressR(editedAddr))



    var res = await axios.post(
      `${GOOGLE_MAP_API_BASE_URL}/json?address=${editedAddr}${zipcode}&sensor=true&key=${GOOGLE_MAP_API_KEY_URL}`
    )
    
    if (res.data.status == 'ZERO_RESULTS') {
      setLoading(false)
      toast.error('Invalid Address', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    }
    if (res.data.status !== 'ZERO_RESULTS') {

      if (res.data?.results?.length > 1) {
        let filteredArr = []
        filteredArr = res.data?.results?.filter(
          elem => elem.formatted_address.toLowerCase().includes(city.toLowerCase())
        )
        setLatitude(filteredArr[0]?.geometry.location.lat)
        setLongitude(filteredArr[0]?.geometry.location.lng)
        
        setLoading(false)
        navigate('/covered-location', { state: filteredArr[0] })
        setZipAction(zipcode)
        return
      } else {
        setLatitude(res.data?.results[0]?.geometry.location.lat)
        setLongitude(res.data?.results[0]?.geometry.location.lng)
        setLoading(false)
        
        navigate('/covered-location', { state: res?.data?.results[0] })
        setZipAction(zipcode)
        return
      }
    }
    
    // if (res.data.status !== 'ZERO_RESULTS') {
    //   setLoading(false)
    //   navigate('/covered-location', { state: res?.data?.results[0] })
    //   setZipAction(zipcode)
    // }
  }


  useEffect(() => {
    window.history.replaceState({}, document.title)
  }, [])

  useEffect(
    () => {
    var config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: '/content/allkeyvalues',
      headers: {}
    }

    axiosInstance(config)
      .then(function (response) {
        
        dispatch(setContent(response.data.data))
        
        
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  useEffect ( 
    ()=>{
     urlInitCall()
    }
  ,[])

  // useEffect(() => {
  //   dispatch(resetState())
  // }, [])

  return (
    <div>
      <div className={`wrapper wrapper-blue ${!loading ? 'd-none' : ''}`}>
        <div class='loader'>
          <div class='loader__part loader__part--1'></div>
          <div class='loader__part loader__part--2'></div>
          <div class='loader__part loader__part--3'></div>
        </div>
      </div>
    </div>
  )
}

export default Search
