import React, { useState, useEffect } from 'react'
import layout from './layout.css'
import axios from 'axios'
import axiosInstance from '../../axios/index_backend'
import {
  GOOGLE_MAP_API_BASE_URL,
  GOOGLE_MAP_API_KEY_URL,
  GOOGLE_MAP_API_KEY
} from '../../constants/apiConstants'

import { httpService } from '../../services/PreQualification'

import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Oval } from 'react-loader-spinner'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAddressR,
  setCstep1,
  setcstepper2,
  setZipAction,
  resetState,
  setInputUserPayload,
  setContent
} from '../../redux/Actions'
var qs = require('qs')
const Home = () => {
  const location = useLocation()

  
  // {zipcode: '71801', serviceType: '1'}

  const [loading, setLoading] = useState(false)
  const [multicoverageid, setMultiCoverageId] = useState('52477')
  const [account, setAccount] = useState('17152')
  const [firstname, setFirstname] = useState('Hunain')
  const [lastname, setLastname] = useState('Arif')
  const [address, setAddress] = useState(
    location.state ? location.state.address : ''
  )
  const [city, setCity] = useState(location.state ? location.state.city : '')
  const [country, setCountry] = useState('Hempstead County')
  const [state, setState] = useState('Arkansas')
  const [zipcode, setZip] = useState(
    location.state ? location.state.zipcode : ''
  )
  const [phonenumber, setPhoneNumber] = useState('+1')
  const [emailaddress, setEmailAddress] = useState(
    'ajay.kumar@icon-solutions.us'
  )
  const [howdidyouhear, setHowDidYouHear] = useState('facebook')
  const [preferredmethod, setPreferredMethod] = useState('Contact field')
  const [besttimetocontact, setBestTimeToContact] = useState(null)
  const [comments, setComments] = useState('hello')
  const [clientip, setClientIP] = useState('39.57.229.116')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [rxmargin, setRxMargin] = useState('')
  const [result, setResult] = useState('')

  const [unitNumber, setUnitNumber] = useState(
    location.state ? location.state?.unitNumber : ''
  )
  const [serviceType, setServiceType] = useState(
    location.state ? location.state.serviceType : ''
  )

  const dispatch = useDispatch()

  let navigate = useNavigate()

  const content = useSelector(state => state.content)

  useEffect(() => {
    window.history.replaceState({}, document.title)
  }, [])

  useEffect(() => {
    var config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: '/content/allkeyvalues',
      headers: {}
    }

    axiosInstance(config)
      .then(function (response) {
        
        dispatch(setContent(response.data.data))
        
        
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  const handleSubmit = async () => {
    if (address == '') {
      toast.error('Please enter the address to proceed', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
      return
    }
    if (city == '') {
      toast.error('Please enter the city to proceed', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
      return
    }
    // if (unitNumber == '') {
    //   toast.error('Please enter the unit number to proceed', {
    //     position: 'top-right',
    //     autoClose: 5000,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined
    //   })
    //   return
    // }
     else if (zipcode == '') {
      toast.error('Please enter the zipcode to proceed', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
      return
    } else if (serviceType == '') {
      toast.error('Please select the service type to proceed', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
      return
    }
    

    if (address !== '' || zipcode !== '' || city!=='') setLoading(true)



    let editedAddr = address

    if(!editedAddr.toLowerCase().includes(city.toLowerCase())){
      editedAddr = editedAddr + ' ' + city + ' '
    }

    let payload = {
      address: editedAddr,
      city: city,
      zipcode: zipcode
    }
    

    dispatch(setInputUserPayload({ address, zipcode, serviceType, city, unitNumber }))

    dispatch(setAddressR(editedAddr))


    

    var res = await axios.post(
      `${GOOGLE_MAP_API_BASE_URL}/json?address=${editedAddr}${zipcode}&sensor=true&key=${GOOGLE_MAP_API_KEY_URL}`
    )
    
    if (res.data.status == 'ZERO_RESULTS') {
      setLoading(false)
      toast.error('Invalid Address', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    }
    if (res.data.status !== 'ZERO_RESULTS') {

      if (res.data?.results?.length > 1) {
        let filteredArr = []
        filteredArr = res.data?.results?.filter(
          elem => elem.formatted_address.toLowerCase().includes(city.toLowerCase())
        )
        setLatitude(filteredArr[0]?.geometry.location.lat)
        setLongitude(filteredArr[0]?.geometry.location.lng)
        
        setLoading(false)
        navigate('/covered-location', { state: filteredArr[0] })
        setZipAction(zipcode)
        return
      } else {
        setLatitude(res.data?.results[0]?.geometry.location.lat)
        setLongitude(res.data?.results[0]?.geometry.location.lng)
        setLoading(false)
        
        navigate('/covered-location', { state: res?.data?.results[0] })
        setZipAction(zipcode)
        return
      }
    }
    
    // if (res.data.status !== 'ZERO_RESULTS') {
    //   setLoading(false)
    //   navigate('/covered-location', { state: res?.data?.results[0] })
    //   setZipAction(zipcode)
    // }
  }

  useEffect(() => {
    dispatch(resetState())
  }, [])

  return (
    <div>
      <div className={`wrapper ${!loading ? 'd-none' : ''}`}>
        <div class='loader'>
          <div class='loader__part loader__part--1'></div>
          <div class='loader__part loader__part--2'></div>
          <div class='loader__part loader__part--3'></div>
        </div>
      </div>
      <header>
        <div className='hero-main'>
          <div className='container'>
            <div className='logo-container'>
              <img src={require('../../images/logo.png')} alt='' />
            </div>
            <h3 className='text-center'>
              {' '}
              {
                content.filter(elem=>elem.key=='ADDRESS_HOME_MAINHEADINGCENTER')[0]?.value
              }
            </h3>

            <div className='mb-3 row'>
              <div className='col-lg-4  '>
                <input
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                  type='text'
                  placeholder='Search For Your Address'
                  className='form-control mb-sm-2'
                  required
                />
              </div>
              <div className='col w-sm-50 mb-sm-2'>
                <input
                  value={city}
                  name='cityname'
                  id='cityname'
                  type='text'
                  placeholder='City'
                  className='form-control'
                  onChange={e => setCity(e.target.value)}
                />
              </div>
              <div className='col w-sm-50 mb-sm-2'>
                <input
                  value={unitNumber}
                  name='unitno'
                  id='unitno'
                  type='text'
                  placeholder='Unit #'
                  className='form-control'
                  onChange={e => setUnitNumber(e.target.value)}
                />
              </div>
              <div className='col w-sm-50 mb-sm-2'>
                <input
                  value={zipcode}
                  name='zipcode'
                  id='zipcode'
                  onChange={e => {
                    const re = /^[0-9\b]+$/
                    // if value is not blank, then test the regex
                    if (e.target.value === '' || re.test(e.target.value)) {
                      dispatch(setZipAction(e.target.value))
                      setZip(e.target.value)
                    }
                  }}
                  type='text'
                  placeholder='Zip code'
                  className='form-control'
                  maxLength={100}
                />
              </div>
              <div className='col w-sm-50 mb-sm-2'>
                <select
                  defaultValue=''
                  className='form-select'
                  onChange={e => setServiceType(e.target.value)}
                  value={serviceType}
                >
                  <option value=''>Service Type</option>
                  <option value='1'>Residential</option>
                  <option value='2'>Commercial</option>
                </select>
              </div>

              <div className='col text-start w-sm-50 mb-sm-2'>
                {/* <Link to='/steps-vizard'> go </Link> */}
                <button
                  onClick={handleSubmit}
                  className='btn btn-info w-sm-100 h-100'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <span>Go</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className='ready-service'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6 pe-5'>
              <h2>{content.filter(elem=>elem.key=='ADDRESS_HOME_HEADINGBOTTOMLEFT')[0]?.value}</h2>
              <p>
                {' '}
                {content.filter(elem=>elem.key=='ADDRESS_HOME_PARABOTTOMLEFT')[0]?.value}
              </p>
            </div>
            <div className='col-lg-6 text-end'>
              <img
                src={require('../../images/young-couple.jpg')}
                className='border-radius-block'
                alt=''
              />
            </div>
          </div>
        </div>

        <ToastContainer toastStyle={{ position: 'fixed' }} />
      </section>
    </div>
  )
}

export default Home
