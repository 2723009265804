import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resetState, setCstep3, setCstep4, setcstepper3, setcstepper4 } from '../../redux/Actions'

function Step5 ({styleE}) {
  const dispatch = useDispatch()
  let navigate = useNavigate()

  const content = useSelector(state => state.content)

  function handleNext () {
    navigate('/')
    dispatch(resetState())
  }
  function handlePrevious () {
    dispatch(setCstep3())
    dispatch(setcstepper3())
  }

  // useEffect(()=>{
  //   setTimeout(()=>{
  //     navigate('/')
  //     dispatch(resetState())
  //   },5000)
  // },[])

  return (
    <div className='panel panel-primary setup-content' id='step-5' style={{...styleE}}>
      <div className='inner-upper-block'>
        <div className='panel-heading'>
          <h3 className='panel-title text-success'>
            {' '}
            {content.filter(elem=>elem.key=='COVERED_SUBMIT_MAINHEADING')[0]?.value}{' '}
          </h3>
        </div>

        <div className='panel-body'>
          <p>
            {' '}
            {content.filter(elem=>elem.key=='COVERED_SUBMIT_PARA')[0]?.value}{' '}
          </p>
        </div>
      </div>
      <div class='_3uApM '>
        {/* <a class='_2pGos _3CDiP  ' onClick={handlePrevious}>
          Previous
        </a> */}
        <a class='_2pGos _3CDiP _2RWmX '>Previous</a>
        <a onClick={handleNext} class='_2pGos _hsN1w '>
          Go to main page
        </a>
      </div>
    </div>
  )
}

export default Step5
