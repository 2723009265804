export const setAmountTableData = obj => {
  return {
    type: 'SET_AMOUNT_TABLE_DATA',
    payload: obj
  }
}
export const setUserFirstname = obj => {
  return {
    type: 'SET_USER_FIRSTNAME',
    payload: obj
  }
}
export const setUserLastname = obj => {
  return {
    type: 'SET_USER_LASTNAME',
    payload: obj
  }
}
export const setUserEmail = obj => {
  return {
    type: 'SET_USER_EMAIL',
    payload: obj
  }
}
export const setUserPhone = obj => {
  return {
    type: 'SET_USER_PHONE',
    payload: obj
  }
}
export const setZipAction = obj => {
  return {
    type: 'SET_ZIP',
    payload: obj
  }
}
export const setLatitude = obj => {
  return {
    type: 'SET_LAT',
    payload: obj
  }
}
export const setLongitude = obj => {
  return {
    type: 'SET_LONG',
    payload: obj
  }
}
export const setLatitudeNC = obj => {
  return {
    type: 'SET_LAT_NC',
    payload: obj
  }
}
export const setLongitudeNC = obj => {
  return {
    type: 'SET_LONG_NC',
    payload: obj
  }
}
export const setAddressR = obj => {
  return {
    type: 'SET_ADDRESS',
    payload: obj
  }
}
export const setCstep1 = () => {
  return {
    type: 'SET_CSTEP1'
  }
}
export const setCstep2 = () => {
  return {
    type: 'SET_CSTEP2'
  }
}
export const setCstep3 = () => {
  return {
    type: 'SET_CSTEP3'
  }
}
export const setCstep4 = () => {
  return {
    type: 'SET_CSTEP4'
  }
}
export const setCstep5 = () => {
  return {
    type: 'SET_CSTEP5'
  }
}
export const setNCstep1 = () => {
  return {
    type: 'SET_NCSTEP1'
  }
}
export const setNCstep2 = () => {
  return {
    type: 'SET_NCSTEP2'
  }
}
export const setNCstep3 = () => {
  return {
    type: 'SET_NCSTEP3'
  }
}
export const setNCstep4 = () => {
  return {
    type: 'SET_NCSTEP4'
  }
}
export const setNCstepper4 = () => {
  return {
    type: 'SET_NCSTEPPER4'
  }
}
// 
export const setAgree = obj => {
  return {
    type: 'SET_AGREE',
    payload: obj
  }
}
export const setcstepper1 = () => {
  return {
    type: 'SET_CSTEPPER1'
  }
}
export const setcstepper2 = () => {
  return {
    type: 'SET_CSTEPPER2'
  }
}
export const setcstepper3 = () => {
  return {
    type: 'SET_CSTEPPER3'
  }
}
export const setcstepper4 = () => {
  return {
    type: 'SET_CSTEPPER4'
  }
}
export const setcstepper5 = () => {
  return {
    type: 'SET_CSTEPPER5'
  }
}
export const setncstepper1 = () => {
  return {
    type: 'SET_NCSTEPPER1'
  }
}
export const setncstepper2 = () => {
  return {
    type: 'SET_NCSTEPPER2'
  }
}
export const setncstepper3 = () => {
  return {
    type: 'SET_NCSTEPPER3'
  }
}
export const setSelectedPackages = obj => {
  return {
    type: 'SET_SELECTED_PKGS',
    payload: obj
  }
}
export const setSelectedServices = obj => {
  return {
    type: 'SET_SELECTED_SERVICES',
    payload: obj
  }
}

export const resetState = () => {
  return {
    type: 'RESET_STATE'
  }
}

export const setNewAddr = (obj) => {
  return {
    type: 'NEW_ADDR',
    payload: obj
  }
}

export const setInputUserPayload = (obj)=>{
  return {
    type: 'SET_INPUT_USER_PAYLOAD',
    payload: obj
  }
} 
export const setContent = (obj)=>{
  return {
    type: 'CONTENT',
    payload: obj
  }
} 
export const setUCFlag = (obj)=>{
  return {
    type: 'UC_FLAG',
    payload: obj
  }
} 
export const setNCAllInfoObj = (obj)=>{
  return {
    type: 'SET_NC_ALL_INFO_OBJ',
    payload: obj
  }
} 
