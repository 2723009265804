import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setUserFirstname,
  setUserLastname,
  setUserEmail,
  setUserPhone,
  setCstep1,
  setCstep3,
  setcstepper3,
  setcstepper1
} from '../../redux/Actions'
function Step2 ({ address, styleE }) {
  const [fnameError, setFnameError] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [phoneError, setPhoneError] = useState(null)

  const dispatch = useDispatch()

  const firstname = useSelector(state => state.firstname)
  const lastname = useSelector(state => state.lastname)
  const email = useSelector(state => state.email)
  const phone = useSelector(state => state.phone)

  const content = useSelector(state => state.content)
  const validateEmail = email => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }
  const validatePhone = phone => {
    var pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    return pattern.test(phone) // returns a boolean
  }
  function handleNext () {
    //add validation logic here

    let ferror = false,
      eerror = false,
      perror = false

    if (firstname == '') {
      setFnameError('Firstname is required')
      ferror = true
    } else if (!/^[a-zA-Z]+$/.test(firstname)) {
      setFnameError('Firstname can only contain alphabets')
      ferror = true
    } else {
      setFnameError(null)
    }

    if (email == '') {
      setEmailError('Email is required')
      eerror = true
    } else if (!validateEmail(email)) {
      setEmailError('Email not valid')
      eerror = true
    } else {
      setEmailError(null)
    }
    if (phone == '') {
      setPhoneError('Phone is required')
      perror = true
    } else if (!validatePhone(phone)) {
      setPhoneError('Phone not valid')
      perror = true
    } else {
      setPhoneError(null)
    }

    if (ferror || eerror || perror) {
      return
    }

    if (firstname == '' || email == '' || phone == '') {
      return
    }

    setEmailError(null)
    setFnameError(null)
    setPhoneError(null)

    dispatch(setCstep3())
    dispatch(setcstepper3())
  }
  function handlePrevious () {
    dispatch(setCstep1())
    dispatch(setcstepper1())
  }

  return (
    <div className='panel panel-primary setup-content' style={{ ...styleE }}>
      <div className='address-block'>
        <strong> Your Address: </strong>
        {address}
        &emsp; 
        {/* <strong> Service Area: </strong> Remote */}
      </div>
      <div className='inner-upper-block'>
        <h2 className='panel-title text-success'>
        {content.filter(elem=>elem.key=='COVERED_CONTACT_MAINHEADING')[0]?.value}
        </h2>
        {/* <br /> */}
        <p className='setup-2-para'>
          
        {content.filter(elem=>elem.key=='COVERED_CONTACT_LINE1')[0]?.value!='' && (<><br/>{content.filter(elem=>elem.key=='COVERED_CONTACT_LINE1')[0]?.value}
          <br />
          <br /></>)}
         {content.filter(elem=>elem.key=='COVERED_CONTACT_LINE2')[0]?.value!='' && ( <>{content.filter(elem=>elem.key=='COVERED_CONTACT_LINE2')[0]?.value}
          <br />
          <br /></>)}
          {content.filter(elem=>elem.key=='COVERED_CONTACT_LINE3')[0]?.value!='' && (content.filter(elem=>elem.key=='COVERED_CONTACT_LINE3')[0]?.value)}
        </p>
        {/* <br /> */}

        <div className='contact-info'>
          <h2>{content.filter(elem=>elem.key=='COVERED_CONTACT_FORMHEADING')[0]?.value}</h2>
          <div className='email-address text-start'>
            <form>
              <div className='row align-items-center name-field'>
                <div className='col-md-6 mb-sm-2'>
                  <label htmlFor='' className='col-form-label'>
                  {content.filter(elem=>elem.key=='COVERED_CONTACT_FORMFIELD_FNAMELABEL')[0]?.value} <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type='text'
                    placeholder='First Name'
                    id='inputPassword6'
                    className='form-control'
                    aria-describedby='passwordHelpInline'
                    onChange={e => {
                      dispatch(
                        setUserFirstname(e.target.value.replace(/[^a-z]/gi, ''))
                      )
                    }}
                    value={firstname}
                    required
                    maxLength={50}
                  />
                  {fnameError ? (
                    <span style={{ color: 'red' }}>{fnameError}</span>
                  ) : (
                    ''
                  )}
                </div>
                <div className='col-md-6 mb-sm-2'>
                  <label htmlFor='' className='col-form-label'>
                  {content.filter(elem=>elem.key=='COVERED_CONTACT_FORMFIELD_LNAMELABEL')[0]?.value}
                  </label>
                  <input
                    type='text'
                    id='inputPassword6'
                    placeholder='Last Name'
                    className='form-control'
                    aria-describedby='passwordHelpInline'
                    value={lastname}
                    onChange={e => {
                      dispatch(
                        setUserLastname(e.target.value.replace(/[^a-z]/gi, ''))
                      )
                    }}
                    maxLength={50}
                  />
                </div>

                <div className='col-md-6 mt-md-3'>
                  <label htmlFor='' className='col-form-label'>
                  {content.filter(elem=>elem.key=='COVERED_CONTACT_FORMFIELD_EMAILLABEL')[0]?.value} <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type='email'
                    placeholder='Email'
                    id='inputPassword6'
                    className='form-control'
                    aria-describedby='passwordHelpInline'
                    value={email}
                    onChange={e => {
                      dispatch(setUserEmail(e.target.value))
                    }}
                  />
                  {emailError ? (
                    <span style={{ color: 'red' }}>{emailError}</span>
                  ) : (
                    ''
                  )}
                </div>
                <div className='col-md-6 mt-md-3'>
                  <label htmlFor='' className='col-form-label'>
                  {content.filter(elem=>elem.key=='COVERED_CONTACT_FORMFIELD_PHONELABEL')[0]?.value} <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type='tel'
                    placeholder='Phone Number'
                    id='inputPassword6'
                    className='form-control'
                    aria-describedby='passwordHelpInline'
                    value={phone}
                    onChange={e => {
                      dispatch(
                        setUserPhone(e.target.value.replace(/[^0-9]/g, ''))
                      )
                    }}
                  />
                  {phoneError ? (
                    <span style={{ color: 'red' }}>{phoneError}</span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class='_3uApM '>
        <a class='_2pGos _3CDiP  ' onClick={handlePrevious}>
          Previous
        </a>
        <button class='_2pGos _hsN1w ' onClick={handleNext}>
          Next
        </button>
      </div>
    </div>
  )
}

export default Step2
