import axios from 'axios'
import { API_BASE_URL_BACKEND } from '../constants/apiConstants'
const instance = axios.create({
  baseURL: API_BASE_URL_BACKEND,
  headers: {
    'Content-Type': 'application/json'
  }
})


instance.interceptors.response.use(null, error => {
  

  const expectedError =
    (error.response &&
      error.response.status >= 400 &&
      error.response.status < 500) ||
    error.response.status == 601

  console.log(error.response)
  console.log('expectedError', expectedError)

  if (expectedError && error.response.status == 601) {
    
  }

  if (!expectedError) {
  }

  if (error.response?.data?.auth == false) {
    //logout user here
    localStorage.clear()
    window.location.href(window.location.origin)
  }

  return Promise.reject(error)
})
export default instance