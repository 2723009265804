import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import StepProgressBar from 'react-step-progress'
import { useLocation, useNavigate } from 'react-router-dom'

// import the stylesheet
import 'react-step-progress/dist/index.css'
import tick from '../../images/done.svg'


import Step1 from './not-covered/Step1'
import Step2 from './not-covered/Step2'
import Step3 from './not-covered/Step3'
const NotCoveredLocation = () => {
  const location = useLocation()
  let navigate = useNavigate()
  
  const [address, setAddress] = useState(location.state?.formatted_address)

  const ncstep1 = useSelector(state => state.ncstep1)
  const ncstep2 = useSelector(state => state.ncstep2)
  const ncstep3 = useSelector(state => state.ncstep3)


  const ncstepper1 = useSelector(state => state.ncstepper1)
  const ncstepper2 = useSelector(state => state.ncstepper2)
  const ncstepper3 = useSelector(state => state.ncstepper3)

  const content = useSelector(state => state.content)

  function step2Validator () {
    // return a boolean
    return true
  }

  function step3Validator () {
    // return a boolean
    return true
  }

  


  return (
    <>
      <header>
        <div className='hero-main'>
          <div className='container '>
            <div className='logo-container'>
              <img src={require('../../images/logo.png')} alt='' />
            </div>
          </div>
        </div>
      </header>

      <div className='container container-progress-form'>
        <ul class='_1Lo2h '>
          
          <li className={`_2Jtxm ${ncstepper1}`}>
            <span class='_2kL0S'><span className='stepper-count'>1</span> <img src={tick} alt="" width="24"/></span>
            <div class='_1hzhf '>{content.filter(elem=>elem.key=='COVERED_STEPS_ONE')[0]?.value}</div>
          </li>
          <li className={`_2Jtxm ${ncstepper2}`}>
            <span class='_2kL0S'><span className='stepper-count'>2</span> <img src={tick} alt="" width="24"/></span>
            <div class='_1hzhf '>{content.filter(elem=>elem.key=='NOTCOVERED_STEPS_TWO')[0]?.value}</div>
          </li>

          <li className={`_2Jtxm ${ncstepper3}`}>
            <span class='_2kL0S'><span className='stepper-count'>3</span> <img src={tick} alt="" width="24"/></span>
            <div class='_1hzhf '>{content.filter(elem=>elem.key=='NOTCOVERED_STEPS_THREE')[0]?.value}</div>
          </li>
        </ul>
        {
          <Step1
            address={address}
            styleE={{ display: ncstep1 ? 'inherit' : 'none' }}
          />
        }
        {
          <Step2
            styleE={{ display: ncstep2 ? 'inherit' : 'none' }}
            address={address}
          />
        }
        {
          ncstep3 &&
          <Step3
            styleE={{ display: ncstep3 ? 'inherit' : 'none' }}
            address={address}
          />
        }
        
      </div>
    </>
  )
}

export default NotCoveredLocation
